import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Container, Row, Col } from "react-bootstrap"
import Seo from "../components/seo"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function portfolioTemplate({ data }) {
  const portfolio = data.markdownRemark
  return (
    <Layout>
      <Seo title={portfolio.frontmatter.title + ` | Portfolio`} />
      <Container className="singleCol">
        <h1 className="text-center">{portfolio.frontmatter.title}</h1>
        <p>

        <GatsbyImage 
          image={portfolio.frontmatter.Image01.childImageSharp.gatsbyImageData} 
          alt={portfolio.frontmatter.title}
        />
        
        <GatsbyImage 
              image={getImage(data.markdownRemark)} 
              alt={portfolio.frontmatter.title}
            />
        </p>
          <hr />
          </Container>
          <Container className="singleCol">
        <Row>
        
          <Col lg={4}>
            <h2>Location</h2>
            <p>{portfolio.frontmatter.location}</p>
          </Col>
          <Col lg={4}>
            <h2>Built with</h2>
            <p>{portfolio.frontmatter.software}</p>
          </Col>
          <Col lg={4}>
            <h2>View Website</h2>              
              {data.markdownRemark.frontmatter.url ? 
            (
              <p><a href={portfolio.frontmatter.url}>{portfolio.frontmatter.url}</a></p>
            ) : 
              <p>Not Available</p> 
          }
          </Col>
        </Row>

        {data.markdownRemark.html ? 
            (
              <article>
              <h2>About {portfolio.frontmatter.title}</h2>
              <div dangerouslySetInnerHTML={{ __html: portfolio.html }} />
              </article>
            ) : 
              null 
          }

        
        
      </Container>
        


                  

                  {/* {data.markdownRemark.frontmatter.featuredImage200 ? (
                    <div className="featured-thumbnail">
                      <Img fluid={data.markdownRemark.frontmatter.featuredImage200.childImageSharp.fluid} />
                    </div>
                  ) : null } */}




        {/* 
        {data.markdownRemark.frontmatter.featuredImage.childImageSharp.fluid === '' && (
            <span>This is only a test</span>
          )}
        {data.markdownRemark.frontmatter.featuredImage.childImageSharp.fluid !== '' && (
            <span>This is only another test</span>
          )} */}
        
    </Layout>
  )
}


export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        location
        software
        url
        Image01 {
          childImageSharp {
            gatsbyImageData(
              webpOptions: {quality: 30}
              placeholder: BLURRED
              jpgOptions: {quality: 50, progressive: false}
            )
          }
        }
      }
    }
  }
` 

// Image01 {
//   childImageSharp {
//     fluid(maxWidth: 1195) {
//       ...GatsbyImageSharpFluid
//     }
//   }
// }